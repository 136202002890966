<template>
    <div>
        <page-title :heading="$t('erp.lang_nav_manufacturer') " show-previous-button :subheading="$t('erp.lang_nav_manufacturer')" :icon=icon></page-title>
        <div class="app-main__inner">
        <ProducerCreate></ProducerCreate>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import ProducerCreate from "../../../components/erp/storage/ProducerCreate";

    export default {
        components: {
            PageTitle,
            ProducerCreate
        },

        data: () => ({
            icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
        })
    }
</script>